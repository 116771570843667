import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "workshops"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Du wolltest schon immer mal Tofu produzieren?
Oder hast du vielleicht schon mal in der Küche Experimente gemacht?
Und jetzt hast du Fragen?`}</p>
    <p>{`Dann komm zu unserem Tofu-Workshop.`}</p>
    <h3>{`Ein Tag mit der Bohne`}</h3>
    <ul>
      <li parentName="ul">{`Tofusuppe`}</li>
      <li parentName="ul">{`Okara als Produkt`}</li>
      <li parentName="ul">{`Was kann ich noch so machen`}</li>
      <li parentName="ul">{`technische Fragen und Antworten`}</li>
      <li parentName="ul">{`Tofu zum mitnehmen`}</li>
    </ul>
    <p>{`[Anmelden][anmeldung@limeriki-tofu.de]`}</p>
    <h3>{`Wo findest du uns?`}</h3>
    <p>{`Unseren Tofu produzieren wir in Leipzig, unsere Workshops machen wir überregional. Gerne gehen wir auf Festivals.
Wir haben eine Reise- und Workshoppresse die uns begleitet und der Rest wird sich finden. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      